import React, { Component } from "react";
import { Grid } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faYoutube,  faFacebook,  faTwitter,  faInstagram,faGoogle } from "@fortawesome/free-brands-svg-icons";
import './Footer.css';

class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <Grid fluid>
          <nav className="pull-left">
          <div className = "social-container " >
         
      <a  href="" className="youtube social" >
      <FontAwesomeIcon icon={faYoutube}  />
 	  </a>
 <a  href="" className="youtube google" >
      <FontAwesomeIcon icon={faGoogle}  />
 	  </a>
 	  <a
  href=""
  className="facebook social"
>
  <FontAwesomeIcon icon={faFacebook}  />
</a>
<a href="" className="twitter social">
  <FontAwesomeIcon icon={faTwitter}  />
</a>
<a
  href=""
  className="instagram social"
>
  <FontAwesomeIcon icon={faInstagram}  />
</a>

</div>
          </nav>
          <p className="copyright pull-right">
            &copy; {new Date().getFullYear()}{" "}
            
            <a href="http://anupamatechnologies.com/">
             All Rights Reserved By Anupama Technologies LLC.
            </a>
           
          </p>
        </Grid>
      </footer>
    );
  }
}

export default Footer;