import React, { Component } from 'react';
import './App.css';
import ReactDOM from "react-dom";
import { BrowserRouter , Route ,browserHistory,HashRouter} from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Services from './components/Services';
import Careers from './components/Careers';
import Clients from './components/Clients';
import Contacts from './components/Contacts';
import Navbar from './components/CustomNavbar';

import Footer from './components/Footer';

class App extends Component {
  render() {
    return (
      <HashRouter>
        <div>
          <Navbar />
          <div>
          <Route exact path="/" component={Home} />
          <Route exact  path="/about" component={About} />
          <Route exact path="/services" component={Services} />
          <Route exact path="/careers" component={Careers} />
          <Route exact path="/clients" component={Clients} />
          <Route exact path="/contacts" component={Contacts} />
          </div>
          <Footer/>
        </div>

      </HashRouter>
    );
  }
}

export default App;
