import React, { Component } from 'react';
import { Grid, Row, Col, Image } from 'react-bootstrap';

//import {Card,CardBlock,CardFooter,CardTitle,CardText} from 'react-bootstrap-card';
import './Services.css';

export default class Services extends Component {
  render() {
    return (
      <div>
        <Image src="assets/services.jpg" className="header-image" />
        <Grid>
          
          <Row className="show-grid text-center">

            <Col xs={12} sm={4} className="person-wrapper">
              <Image src="assets/j2ee1.png" circle className="profile-pic"/>
             <h3>J2EE</h3>

              <p align="justify">Anupama Technologies LLC J2EE practice is comprised of J2EE professionals with many years of experience in developing applications for medium as well as large scale enterprises. Our technology specialists are constantly involved in projects which require development of applications that work and work well under rigid conditions with strict performance requirements. Anupama Technologies LLC IT has experience working with Application servers like IBM Websphere, BEA's WebLogic server and Tomcat on various operating platforms. In addition, our professionals are conscious of the various differences that exist on application servers as well as their implementation on various operating platforms. Ability to tune and maintain applications in production environment is a skill that comes with experience and our experts have demonstrable experience in this area.</p>
            </Col>

             <Col xs={12} sm={4} className="person-wrapper">
              <Image src="assets/commerce.png" circle className="profile-pic"/>
             <h3 >IBM Websphere commerce</h3>

              <p align="justify">Every merchant face number of challenges every day changing commerce landscape with number of channels to sell, number of devices to support, social networks to integrate, staying on top of search engines and every day changing customer behaviour. IBM Websphere commerce suite is one stop shop with new version, store fronts based on current demands, robust architecture and powerful promotions and marketing tools. Omni-channel engagements provide a seamless and continuous experience - whether your customer is shopping online from a desktop or mobile device, by telephone or in a brick and mortar store.New implementation by using OOB features and minimizing customizations.Migrating from other platforms.Upgrade to new version or fixpack
Performance tuning and holiday readyness
PCI compliance
24 X 7 support using global devliery model</p>


            </Col>

<Col xs={12} sm={4} className="person-wrapper">
<div>
            <Image src="assets/oracle.jpg" circle className="profile-pic"/>
            <h3>ORACLE e-business suite </h3>
            <p align="left" ><font size="2">We Offer Both onshore and offshore model in techinical and techno-functional services.
 Services include:Solution Design,Development,Migraion,Conversion,Testing etc.</font></p>
<p align="justify" align="justify"><b>We support in the following modules :</b></p>

<p align="left" ><font size="2">Inventory Management,Order Management,Shipping Execution,Procurement,WMS,WIP,OSFM,e-Commerce Gateway, XML Gateway,ASCP and Demantra.</font></p>


<p align="left" >Technical Services - Forms, Reports, Interfaces, Conversions, WebADI, Dataloader, Testing etc.</p>

<p align="justify">We also offer our services in coordinating with Third Parties and Service Providers (like 3PL's, DI-Central, SPS Commerce, E2Open etc) for data transfers.</p>
</div>
          </Col>
</Row>

<Row className="show-grid text-center">
          <Col xs={12} sm={4} className="person-wrapper">
              <Image src="assets/netsuite.png" circle className="profile-pic"/>
              <h3>NetSuite</h3>
               
              <div>

              <p align="justify"> Our NetSuite development services extend and enhance the value of your software systems by designing, creating and developing unique dashboards, reports, settings and more that adapt to your business needs, using SuiteScript, SuiteAnalytics, SuiteFlows, SuiteBundle and SuiteBuilder developer tools.
                 Our NetSuite developers have extensive experience seamlessly migrating all business data found on any application, systems, database, website, third-party software, servers, operating systems and more, into you ERP platform allowing you to have a complete overview of your business. Our migration services ensure all important data is stored secure and maintains integrity.</p>
                </div>
            </Col>
            
             <Col xs={12} sm={4} className="person-wrapper">
              <Image src="assets/net1.jpg" circle className="profile-pic"/>
             <h3 >.Net</h3>

              <p align="justify">We are one of the leading Dot Net application development companies in India and USA. We along with the bunch of our awesome development team aim to put forward the most reliable, secured, and scalable solutions.We offers a wide range of .Net based services and has proven capabilities to build robust and scalable web and enterprise based web applications leveraging .Net to its best. Through a common set of functionality, .NET allows these components to work reliably with each other. Because of this it has been the most sought after framework for businesses across the globe, irrespective of the size and nature of their business.</p>
            </Col>

            <Col xs={12} sm={4} className="person-wrapper">
              <Image src="assets/images.png" circle className="profile-pic"/>
             <h3 >Web Development</h3>

              <p align="justify">Web development is a term that is used to refer to the process of creating a website and can range from developing a single simple page to a series of complex pages. Web development encompasses several actions or practices and some of them include web design, content creation, programming, network security tasks as well as client side or server side scripting, etc. In the recent few years, web development has taken the definition of the creation of content management systems or CMS, which is the mid-step between the user and the database. In order to pursue web development as a profession, one of the most important things that you will need to consider is having expertise in programming languages.</p>
            </Col>
</Row>
            <Row className="show-grid text-center">
            <Col xs={12} sm={4} className="person-wrapper">
              <Image src="assets/react.png" circle className="profile-pic"/>
              <h3>React</h3>
              <p align="justify">We provide world-class ReactJS development services, and we are globally renowned for offering best-fit technology solutions to our clients and focus on bringing up truly progressive web and mobile applications to meet the specific business objectives. We have a proficient team of React specialists with extensive experience in ReactJS development services. We design a creative mobile and web application UI/UX to fulfill the unique needs of our clients. As a prominent app development services rendering company we hold huge experience in crafting and developing outstanding web app motorized with React/Redux.</p>
            </Col>
            <Col xs={12} sm={4} className="person-wrapper">
              <Image src="assets/hosting.jpg" circle className="profile-pic"/>
              <h3>Hosting</h3>
              <p align="justify">We can help you with hosting solutions using Amazon EC2 instances. Amazon EC2 offers best hosting solutions. Cloud based systems are low cost solutions, flexible, scalable and can be switched to on-demand offering. These are also very useful for test environments like integration, QA which may not be required all the time. With Amazon on-demand offering we can help you maintain multiple test environments at the same time reduce overall cost as instances can be shut down any time.We can help you decide best hosting option based on your requirements, creating instances, deploying applications and maintaining your system.</p>
            </Col>

            <Col xs={12} sm={4} className="person-wrapper">
              <Image src="assets/appdevelopment.jpg" circle className="profile-pic"/>
              <h3>App Development</h3>
              <p align="justify">We  Provides a business-oriented and feature-rich mobile apps that would perform well on Android and iOS platforms.As a prominent app development services rendering company we hold huge experience in crafting and developing outstanding web app motorized with React/Redux.Our experts have resolved several challenges like multiple frameworks, SPA and many more using their skill in React & Angular.Carrying the responsibility of delivering results par excellence with each solution we dispense, we assist you in powering your digital business with an intuitive and flexible React.JS interface for your web and mobile apps. Our commitment to accomplish the assignment within the specified deadlines .</p>
            </Col>
 
             


            <Col xs={12} sm={4} className="person-wrapper">
           <Image src="assets/dw1.jpg" circle className="profile-pic"/>
             <h3 >BI AND DW SERVICES</h3>

              <p align="justify">Anupama Technologies LLC offer end-to-end BI and DW services - Reporting and Analytics, Maintenance and Support. Our services cover Business Intelligence Reporting, Visualizations and Dashboards, Data Warehousing implementation, Analytics, Data Mining, Data Quality and Master Data Management. We have substantial experience in deliverying high-quality and easily managed DW/BI solutions. We help clients to enable strategic implementation completely aligned with their existing and future IT strategy.</p>
            </Col>

           

           
          </Row>
         
        </Grid>
      </div>
    )
  }
}
