import React, { Component } from 'react'
import {  Image, Grid, Row, Col} from 'react-bootstrap';

import './About.css';



export default class Clients extends Component {

  

  render() {

    
    return (
      <div>
        <Grid>
        <h1>CLIENTS</h1>
  <Row>
   
    <Col xs={9} md={4}>
     <a href="https://www.lds.org/?lang=eng"> <Image src="assets/29.png"   style={{width: 275, height: 100}}/></a>
    </Col>
    <Col xs={9} md={4}>
      <a href="https://www.trailsbest.com"> <Image src="assets/31.png"   style={{width: 275, height: 100}}/></a>
    </Col>
    <Col xs={9} md={4}>
    <a href="https://www.mazdamotorsports.com"> <Image src="assets/33.png"   style={{width:275, height: 100}}/></a>
    </Col>
    <Col xs={9} md={4}>
    <a href="https://www.wildbillsfoods.com/"> <Image src="assets/34.png"   style={{width:200, height: 200}}/></a>
    </Col>
    <Col xs={9} md={4}>
    <a href="https://www.trailsbest.com"> <Image src="assets/32.png"   style={{width:200, height: 200}}/></a>
    </Col>
    <Col xs={9} md={4}>
    <a href="https://littleunicorn.com/"> <Image src="assets/38.png"   style={{width:200, height: 200}}/></a>
    </Col>
    <Col xs={9} md={4}>
    <a href=""> <Image src="assets/35.png"   style={{width:200, height: 200}}/></a>
    </Col>
  </Row>

  <h1>PARTNERS</h1>
  <Row>
   
    <Col xs={9} md={4}>
     <a href="https://themortonway.com"> <Image src="assets/25.png"   style={{width: 250, height: 100}}/></a>
    </Col>
    <Col xs={9} md={4}>
      <a href="https://www.zobristinc.com"> <Image src="assets/zobrist.png"   style={{width: 250, height: 100}}/></a>
    </Col>
   
  </Row>

</Grid>
      </div>
    );
  }
}
