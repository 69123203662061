import React, {Component} from 'react';
import axios from 'axios';
import { Grid,Row, Col,Button } from "react-bootstrap";
import './Contacts.css';

class Contacts extends Component{
  
    handleSubmit(e){
        e.preventDefault();
        const name = document.getElementById('name').value;
        const email = document.getElementById('email').value;
        const message = document.getElementById('message').value;
        axios({
            method: "POST", 
            url:"http://localhost:3002/send", 
            data: {
                name: name,   
                email: email,  
                messsage: message
            }
        }).then((response)=>{
            if (response.data.msg === 'success'){
                alert("Message Sent."); 
                this.resetForm()
            }else if(response.data.msg === 'fail'){
                alert("Message failed to send.")
            }
        })
    }

    resetForm(){
        document.getElementById('contact-form').reset();
    }

    render(){
        return(

     <Grid>

            <div >
                   <h3>Drop Your Message</h3>

                   <p align="center">We are here to answer any questions you may have about our Anupama Technologies LLC. Reach out to us and we'll respond as soon as we can.</p>
                <form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
                    <div className="form-group">
                        <label for="name">Name</label>
                        <input type="text" className="form-control" id="name" />
                    </div>
                    <div className="form-group">
                        <label for="exampleInputEmail1">Email address</label>
                        <input type="email" className="form-control" id="email" aria-describedby="emailHelp" />
                    </div>
                    <div className="form-group">
                        <label for="message">Message</label>
                        <textarea className="form-control" rows="5" id="message"></textarea>
                    </div>
                    <button type="submit" className="btn btn-primary">Submit</button>
                </form>
            </div>

            <h3>ADDRESS</h3>
           <div className="pull-left">
          <h3>USA</h3>
          <ul >
          <li className="style" >Anupama Technologies LLC.</li>
<li className="style">121 Fort Cobb Way,</li>
<li className="style">Georgetown,</li>
<li className="style">TX 78628,USA.</li>
<li className="style">Tel: +1 913-568-2634,</li>

<li className="style"><a href="
salesus@anupamatechnologies.com">
salesus@anupamatechnologies.com</a></li>
</ul>

           </div>

           <div className="pull-right">
          <h3>INDIA</h3>
          <ul>
          <li className="style">Kyber Technology Services (P) Ltd.</li>
<li className="style">D.No.40-1-520, Behind BATA stores,</li>
<li className="style">M.G.Road, Vijayawada-520010,
India.</li>
<li className="style">Tel: +91 9032043089,</li>

<li className="style"><a href="mailto:sales@kyberservices.com">salesus@kyberservices.com</a></li>
</ul>

           </div>
            </Grid>
        )
    }
}

export default Contacts; 